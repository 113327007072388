
:root {
    --landscape-width: 18  ;
    --main-width: 50;
    --portrait-width: 80vw;
    --header-height: 2.5rem;
    --secs: 0.4s;
    /* --bg-left: #f1f1f1; */
    --bg-left: white;
    --bg-right: white;
    /* --bg-right: #f1f1f1; */
    --bg-main: rgb(255, 255, 255);
}
.chamado body {
    max-height: 100vh;
    max-width: 100vw;
    margin: 0;
    overflow: hidden;
}

.modal-body{
    padding: unset;
}
.modal-hg, .modal-xl{
    max-width: 98%;
}
#chamado {
    display: flex;
    overflow: hidden;
}
#chamado .card-header-pills{
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-right: 0;
    margin-left: 0;
}
#chamado .header {
    position: relative;
    height: var(--header-height);
    text-align: center;
    margin-bottom: 1rem;
    justify-content: center;
}
#chamado .header .title {
    position: absolute;
    left: 0;
    right: 0;
}
#chamado .content {
    overflow: auto;
    box-sizing: border-box;
    height: calc(100vh - var(--header-height));
    max-height: 75vh;
    margin-top: auto;
    padding-top: 0px;
    border-left: solid;
    border-color: gainsboro;
}
#main {
    margin-left: -1rem;
    margin-right: -1.2rem;
    background: var(--bg-main);
    flex-grow: 1;
    min-width: 35%;
    overflow: auto;
    box-sizing: border-box;
    height: calc(100vh - var(--header-height));
    max-height: 75vh;

}
.chat{
    display: flex;
    background-color: unset;
}

#main .nav-tabs{
    justify-content: center;
}
#main .nav-pills .nav-link.active, .nav-pills .show>.nav-link{
    background-color: unset;
    border-radius: 0px;
}
#main a:hover{
    color: var(--dommus-color);
}
#main .nav-link.active{
    color: var(--dommus-color) !important;
    border-color:  var(--dommus-color);
    border-bottom: solid;
}
#main .chat a{
    color: #b8b8b8;
    border-bottom: solid;
    border-radius: unset;
}


#main .header {
    background: linear-gradient(to right, var(--bg-mai), var(--bg-main));
}
@media (orientation: landscape) {
    

    #main .header .title {
        position: initial;
        transition: left var(--secs), right var(--secs);
        color: white;
        
        
   }
    #main .header .title.left-closed {
        left: var(--header-height);
   }
    #main .header .title.right-closed {
        right: var(--header-height);
   }
}

 /* LATERAIS */

#left, #right {
    transition: width var(--secs);
    width: 0;
}
@media (orientation: landscape) {
    #left.open, #right.open {
        width: calc(3% * var(--landscape-width));
        max-width: 28%;
   }
}
#left .icon, #right .icon{
    
    position: absolute;
    z-index: 10;
    width: var(--header-height);
    height: var(--header-height);
    line-height: var(--header-height);
    font-size: var(--header-height);
    text-align: center;
    user-select: none;
    cursor: pointer;
}
#left .icon{
    padding-left: 1.4rem;
}

#left .sidebar, #right .sidebar {
    transition: transform var(--secs);
}
@media (orientation: portrait) {
    #left .sidebar, #right .sidebar {
        width: var(--portrait-width);
   }
}
@media (orientation: landscape) {
    #left .sidebar, #right .sidebar {
        width: calc(1.5vw * var(--landscape-width));
   }
}

/* ESQUERDA */

/* #left .sidebar .header, #right .sidebar .header {
    width: calc(100% - var(--header-height));
} */
#left {
    z-index: 5;
}
#left .sidebar {
    background: var(--bg-left);
}
#left .sidebar.closed {
    transform: translateX(-101%);
}
/* #left .sidebar .header {
    left: var(--header-height);
} */

.headerChamado{
    text-align: center;
}
.infoUser{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.infoUser .profile{
    margin: 1rem;
}


/* CHAT */

.listaMensagens {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 63vh;
  }
  
  .listaMensagens-header {
    background-color: #262626;
    overflow: visible;
    width: 100%;
    text-align: center;
    color: white;
  }
  
  .listaMensagens .Messages-list {
    padding-right: 1rem;
    padding-left: 1rem;
    width: 100%;
    margin: 0 auto;
    list-style: none;
    font-size: 12px;
    
    flex-grow: 1;
    overflow: auto;
    /* overflow-y: scroll; */
  }
  
  .listaMensagens .Messages-message {
    display: flex;
    margin-top: 10px;
  }
  
  .listaMensagens .Messages-message.currentMember {
    justify-content: flex-start;
    flex-direction: row-reverse;
    text-align: right;
  }
  
  .listaMensagens .Message-content {
    display: inline-block;
  }
  
 .currentMember > .Message-content {
    align-items: flex-end;
  }
  
  .Messages-message > .avatar {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    display: inline-block;
    margin: 0 10px -10px;
  }
  
     .Message-content > .username {
    display: block;
    color: gray;
    font-size: 10px;
    padding-bottom: 4px;
  }
  
    .Message-content > .text {
    padding: 10px;
    max-width: 400px;
    margin: 0;
    border-radius: 12px;
    background-color:#b6b6b6;
    color: white;
    display: inline-block;
  }
  
    .currentMember > .Message-content .text {
    background-color: var(--dommus-color);
  }
  
  .listaMensagens form {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    display: flex;
    width: 100%;
    justify-content: space-between;
    /* margin: 0 auto 40px; */
  }
  
  .listaMensagens input {
    padding: 5px;
    font-size: 16px;
    border-radius: 8px;
    border: 1px solid var(--dommus-color);
    flex-grow: 1;
  }
  
  .listaMensagens button {
    padding: 5px 10px;
    font-size: 16px;
    background-color: var(--dommus-color);
    color: white;
    border: none;
    border-radius: 8px;
    margin-left: 10px;
    height: 86px;
  }

  .inputMensagens{
      padding-bottom: unset;
  }
/* CHAT - FIM */

/* .selectStatus option[value="pendente"]{
    color:white;
    background-color: crimson;
} */
