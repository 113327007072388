.listaMensagens button{
    margin-left: unset !important;

}

.inputMensagem:focus{
    box-shadow: unset;
}

.enviarMensagem{
    height: calc(1.5em + .75rem + 2px);
    font-size: 16px;
}

input[type="file"]#file-upload {
    display: none;
}
.custom-file-upload {
    font-size: 16px;
    background-color: var(--dommus-color) ;
    border: 1px solid var(--dommus-color);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}

#Input{
    margin-left: 10px;
}

.upload-area{
    padding: 10px;
}

.upload-area p{
    margin: 0;
    width: 100%;
    height: 120px;
    border: 1px dashed #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
}

.txtArea-chat {
    width: 88%;
    height: 86px;
    border-radius: 7px;
    padding: 7px;
}

.anexo-section{
    display: flex;
    flex-direction: row;
    gap: 10px;
    cursor: pointer;
}

.anexo-section:hover span{
    color: var(--dommus-color-2);
}