
td.MuiTablePagination-root{
    overflow:unset;
}

.acessar svg{
    cursor: pointer;
}
.addIndicacao{
    cursor: pointer;
    font-size: 3rem;
	float: bottom;
	bottom: 15px;
	right: 2rem;
	z-index: 100;
}

.chamadoStatus2{
    transform-origin: unset;
    top: unset;
}
.badgeBase {
    color: white;
    font-weight: bold;
    padding: 5px 3px;
    border-radius: 5px;
}



.containerMobuss{
    width:350px;
    display:flex;
    justify-content:space-between;
    align-items:center
}
.mobussButton{
    background:linear-gradient(to top,#1a4270,#122c4b);
    font-weight: 500;
    color: #FFF;
    border:1px solid #122c4b
}