.coluna_direita{
    padding: 4px;
}

.coluna_direita .accordion_iten{
    cursor: pointer;
}
#direita .header {
    display: contents;
    position: relative;
    height: var(--header-height);
    text-align: center;
    padding-bottom: 2rem;
    justify-content: center;
}
/* DIREITA */

#right .icon {
    right: 1.4rem;
 }
 
 #right .sidebar {
     background: var(--bg-right);
     margin: auto;
 }
 
 @media (orientation: portrait) {
     #right .sidebar.open {
         transform: translateX(-100%);
    }
 }

 #direita .nav-tabs{
    justify-content: center;
}
#direita .nav-pills .nav-link.active, .nav-pills .show>.nav-link{
    background-color: unset;
    border-radius: 0px;
}
#direita .nav-link{
    text-align: center;
    padding-left: 0;
    padding-right: 0;
}
#direita a:hover{
    color: var(--dommus-color);
}
#direita .nav-link.active{
    color: var(--dommus-color) !important;
    border-color:  var(--dommus-color);
    border-bottom: solid;
}
#direita .menu_direita a{
    color: #b8b8b8;
    border-bottom: solid;
    border-radius: unset;
}
#direita .input-group-append{
    align-self: center;
}

#direita .fotoEnvolvido svg{
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 50px;
}

#direita .fotoEnvolvido{
    font-size: 95px;
    line-height: 1;
}
#direita .dadosPessoaisCliente{
    margin-bottom: 0.5rem;
}

#direita .accordion{
    padding-top: 1rem;
}