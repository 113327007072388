section.equipes {
    position: fixed;
    right: 0;
    top: 75px;
    min-width: 50%;
    height: calc(100% - 75px);
    background-color: #fff;
    transform: translateX(100%);
    z-index: 12;
    transition: var(--smooth);
    padding: 10px;
    overflow: auto;
}

section.equipes.open {
    transform: translateX(0);
    border-left: 1px solid #f2f3f8;
}

section.equipes header {
    padding: 15px;
    border-bottom: 1px solid #f2f3f8;
    font-weight: 700;
    font-size: 14px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

section.equipes header .close-pane {
    height: 100%;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    width: 55px;
    justify-content: center;
}

.sidenav-overlay.open {
    display: block !important;
    opacity: 1;
}
.sidenav-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0;
    height: 120vh;
    background-color: rgba(0,0,0,.5);
    z-index: 7;
    display: none !important;
    transition: var(--smooth);
}

.rbc-event.event-finished {
    background: #359835;
}

@media (max-width:767px) {
    section.equipes.open {
        width: 100% !important;
    }
}