* {
  padding: 0;
  margin: 0;
  outline: 0;
  box-sizing: border-box;
}

html, body, #root{
  min-height: 100%;
}

body{
  font-family: 'Poppins', Arial, Helvetica, sans-serif !important;
  font-size: 14px;
  background-color: var(--dommus-background-color) !important;
}

.wrapper{
  display: flex;
  flex-direction: row;
  max-width: 100%;
  justify-content: space-between;
}

.content{
    width: calc(100% - 50px);
    padding: 15px;
    margin-top: 75px;
    overflow-x: hidden;
}

/* Variaveis */

:root{
    /* Cores */
    --dommus-color:#0A71B2;
    --dommus-color-2:#f19d1f;
    --dommus-color-secondary:#64B5F6;
    --dommus-color-cinza:#868aa8;
    --dommus-color-green:#4CAF50;
    --dommus-color-red:#f44336;
    --dommus-color-dark:#353252;
    --dommus-shadow:0 2px 4px rgba(0,0,0,0.1);
    --dommus-b-radius: 5px;
    --dommus-overflow-width:6px;
    --dommus-overflow-height:6px;
    --dommus-background-color:#f2f3f8;
    --dommus-cor-logo: unset;


    /*  */
    --smooth:200ms ease-in-out;
}

*::-webkit-scrollbar-track
{
    background-color: #ddd;
}
*::-webkit-scrollbar
{
    width:var(--dommus-overflow-width);
    height: var(--dommus-overflow-height);
    background-color: #ddd;
    border-radius: 10px;
}

body::-webkit-scrollbar {
    width: 12px;
}
*::-webkit-scrollbar-thumb
{
    background-color: var(--dommus-color);
    border-radius: 10px;
}

.form-control .form-control-color{
    padding: .375rem 14rem !important;
}
.form-group .react-datepicker-wrapper{
    width: 100%;
}

.icon-em-construcao{
    cursor: not-allowed;
    color: var(--dommus-color-cinza);
    opacity: 40%;
}

.buttonNovaOportunidade.dropup {
    background: none;
}
.buttonNovaOportunidade.dropup .dropdown-toggle::after {
    content: none;
}
.buttonNovaOportunidade.dropup .btn {
    background-color: transparent;
}

.buttonNovaOportunidade .btn.btn-primary {
    background-color: var(--dommus-color);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 30px;
    bottom: 30px;
    padding: 15px;
    font-size: 35px;
    border-radius: 100%;
    cursor: pointer;
}

.inputLabel{
    color: #868aa8;
    font-size: 12px;
    margin-bottom: 0;
}

h2 {
    font-size: unset;
    margin: 0;
}

.formCadastro label{
    color: #868aa8 !important;
    font-size: 12px;
    font-weight: 400 !important;
    margin-bottom: 0;
}

.formCadastro .form-control:focus{
    box-shadow: 0 2px 0 0 var(--dommus-color);
    border-color: var(--dommus-color);
}

.formCadastro .form-group{
    padding-top: 0;
    margin-bottom: 1rem;
}

.formCadastro .lastRow{
    border-bottom: 0;
}

.formCadastro .formFooter{
    border-top: 1px solid #cccc;
    border-bottom: 0;
    padding-top: 10px;
}

.formCadastro .formFooter .col{
    justify-content: flex-end;
    align-items: center;
    display: flex;
}

.documentacao-item-wrapper h6 {
    padding: 15px;
    padding-bottom: 0;
    font-size: 14px;
    font-weight: 700;
}

.documentacao-item-wrapper {
    border: 1px solid #ddd;
    border-radius: 0 5px 0 0;
    background-color: #f2f3f8;
    margin-bottom: 10px;
}
.documentacao-item-wrapper .upload-wrapper {
    margin: 15px;
    background-color: #fff;
    margin-top: 0;
    justify-content: space-between;
}
.upload-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px;
    border: 1px dashed #c0bfbf;
    border-radius: var(--dommus-b-radius);
    transition: var(--smooth);
}
.documento-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
}
.documentacao-item-wrapper .ajax-file-upload-container {
    display: flex;
    width: 100%;
}
.documentacao-item-wrapper .ajax-file-upload-container a {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    font-weight: 700;
    width: 100%;
    color: #353252;
}
.documentacao-item-wrapper .status {
    position: relative;
    background-color: unset;
    height: unset;
    color: unset;
    font-weight: unset;
    display: unset;
    padding: unset;
    justify-content: unset;
    align-items: unset;
    border-radius: unset;
    cursor: unset;
}
.status img {
    width: 35px;
    height: 35px;
}
.documentacao-item-wrapper .ajax-file-upload-container a span {
    font-size: 10px;
    color: #868aa8;
    font-weight: 400;
}
.documentacao-item-wrapper .topoArquivo{
    display: flex;
    justify-content: space-between;
}

.documentacao-item-wrapper .topoArquivo .dropdown{
    align-items: center;
    display: flex;
}

.documentacao-item-wrapper .topoArquivo button{
    background-color: unset;
    border-color: unset;
    padding: 0;
    outline: none;
    border: 0;
    margin-right: 10px;
}
.documentacao-item-wrapper .topoArquivo button::after{
    display: none;
}
.documentacao-item-wrapper .topoArquivo button:active {
    color: unset !important;
    background-color: unset !important;
    border-color: unset !important;
}