.menu{
    border-bottom : unset !important;
}
.menu_esquerda{
    flex-wrap: wrap;
    background-color: unset;
    padding-bottom: 1rem;
}


#esquerda .nav-tabs{
    justify-content: center;
}
#esquerda .nav-pills .nav-link.active, .nav-pills .show>.nav-link{
    background-color: unset;
    border-radius: 0px;
}
#esquerda .nav-link{
    text-align: center;
    padding-left: 0;
    padding-right: 0;
}

#esquerda a:hover{
    color: var(--dommus-color);
}
#esquerda .nav-link.active{
    color: var(--dommus-color) !important;
    border-color:  var(--dommus-color);
    border-bottom: solid;
    border-radius: unset;
}
#esquerda .menu_esquerda a{
    color: #b8b8b8;
    border-bottom: solid;
    border-radius: unset;
}
#esquerda .input-group-append{
    align-self: center;
    margin-left: 5px;
}

#esquerda .header{
    display: initial;
    position: relative;
    height: var(--header-height);
    text-align: center;
    margin-bottom: 1rem;
    justify-content: center;
}

.react-datepicker-wrapper {
    width: 100%!important;
}