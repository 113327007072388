.statusIndicado{
    color: white;
    background-color:#6997B4;
    padding: 0.5rem;
    border-radius: 10px;
    
}

.statusComprou{
    color: white;
    background-color:#4CAF50;
    padding: 0.5rem;
    border-radius: 10px;
    
}

.statusPendente{
    color: white;
    background-color:var(--dommus-color-2);
    padding: 0.5rem;
    border-radius: 10px;
    
}

.statusContemplado{
    color: white;
    background-color:#353252;
    padding: 0.5rem;
    border-radius: 10px;
    
}
